import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import Logo from '../../assets/images/kinetik/logo2.png'
import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Unternehmen</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/about-us">
                                        Über uns
                                    </Link>
                                </li>
                                
                                {/*<li>
                                    <Link to="/produkte">
                                        Produkte
                                    </Link>
                                </li>
                                    <Link to="/features">
                                        Features
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Our Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog-1">
                                        Latest News
                                    </Link>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Rechtliches</h3>
                            <ul className="list">
                                <li>
                              <Link to="/Impressum">
                                Impressum
                                </Link>
                                </li>
                                <li>
                                <Link to="/Rechtliches">Rechtliches</Link>
                                </li>

                                <li>
                                <Link to="/Datenschutzerklärung">Datenschutzerklärung</Link>
                                </li>
                                {/*<li>
                                    <Link to="/team">
                                        Team
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </li>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Adresse</h3>
                            
                            <ul className="footer-contact-info">
                                <li> 
                                    <Icon.MapPin />
                                    <span>Friedrich-Wilhelm-Raiffeisen-Straße 3</span> 95463 Bindlach
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <Link to="mailto:info@kinetik.de">info@kinetik.de</Link>
                                </li>
                                <li> 
                                    <Icon.PhoneCall />
                                    Phone: <Link to="tel:00499217300500">09217300500</Link>
                                </li>
                            </ul>

                            {/*<ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                </li>
                            </ul>*/}
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} KiNETiK GmbH. All rights reserved.</p>
                        
      						<p> Made by Nico</p>                  
                        
                        </div>
                    </div>
                </div>
            </div>

            <img src={MapImg} className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </footer>
    )
     
}

export default Footer; 